import * as constants from './constants';

const userState = {
  userInfo: null, //个人所有信息
  isMobile: null, //校验当前设备
  id: null, //用户id
  name: null, //用户的nickname
  mobile: null, //用户手机号码
  email: null, //用户邮箱
  gender: null, //性别
  schoolId: null, //当前学校id
  schoolName: null, //当前学校名称
  avatar: null, //头像url
  roles: [], //【0】管理员;【1】教师;【2】学生,
  unreadMessageNumbers: null, //消息未读数,
  schoolOptions: []
};

const disposeRoles = (data: any) => {
  let roles: Array<any> = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      roles.push(data[i].id);
    }
  }
  return roles;
};

type userState = typeof userState;

/* 暴露请求返回的数据 */
const userReducer = (state = userState, action: { [type: string]: any }) => {
  switch (action.type) {
    case constants.CHANGE_USER_INFO:
      let currentRole = disposeRoles(
        JSON.parse(JSON.stringify(action.value.data.currentSchool.roles))
      );
      return {
        ...state,
        userInfo: action.value.data,
        id: action.value.data.id,
        name: action.value.data.nickname,
        mobile: action.value.data.mobile,
        email: action.value.data.email,
        gender: action.value.data.gender,
        schoolId: action.value.data.currentSchool.id,
        schoolName: action.value.data.currentSchool.name,
        avatar: action.value.data.portraitUrl,
        roles: currentRole,
        unreadMessageNumbers: action.value.data.unreadMessageNumbers,
        schoolOptions: action.value.data.schools
      };
    default:
      return state;
  }
};

export default userReducer;

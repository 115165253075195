// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".EvaluationLayout_evaluation-layout-wrap__32qJ5{width:100%;height:100%;min-height:100%;position:relative}.EvaluationLayout_evaluation-layout-wrap__32qJ5 .EvaluationLayout_evaluation-layout-header__1yNXA{width:100%;position:fixed;left:0;top:0;z-index:10}.EvaluationLayout_evaluation-layout-wrap__32qJ5 .EvaluationLayout_evaluation-layout-section__1ySbF{width:100%;min-height:100%;padding-top:60px;background-color:#fff}.EvaluationLayout_evaluation-layout-wrap__32qJ5 .EvaluationLayout_evaluation-layout-footer__12rpw{width:100%}", "",{"version":3,"sources":["D:\\文件\\companyProject\\examNew\\ddcode-exam/src\\layouts\\EvaluationLayoutNoFooter\\EvaluationLayout.module.scss"],"names":[],"mappings":"AAGA,gDACE,UAAA,CACA,WAAA,CACA,eAAA,CACA,iBAAA,CAEA,kGACE,UAAA,CACA,cAAA,CACA,MAAA,CACA,KAAA,CACA,UAAA,CAEF,mGACE,UAAA,CACA,eAAA,CACA,gBAAA,CACA,qBAAA,CAEF,kGACE,UAAA","file":"EvaluationLayout.module.scss","sourcesContent":["@import '../../styles/variables.scss';\n@import '../../styles/mixin.scss';\n\n.evaluation-layout-wrap {\n  width: 100%;\n  height: 100%;\n  min-height: 100%;\n  position: relative;\n\n  .evaluation-layout-header {\n    width: 100%;\n    position: fixed;\n    left: 0;\n    top: 0;\n    z-index: 10;\n  }\n  .evaluation-layout-section {\n    width: 100%;\n    min-height: 100%;\n    padding-top: 60px;\n    background-color: #fff;\n  }\n  .evaluation-layout-footer {\n    width: 100%;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"evaluation-layout-wrap": "EvaluationLayout_evaluation-layout-wrap__32qJ5",
	"evaluation-layout-header": "EvaluationLayout_evaluation-layout-header__1yNXA",
	"evaluation-layout-section": "EvaluationLayout_evaluation-layout-section__1ySbF",
	"evaluation-layout-footer": "EvaluationLayout_evaluation-layout-footer__12rpw"
};
module.exports = exports;

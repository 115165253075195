/* eslint-disable-next-line */
export const EXAM_INFO = '/EXAM_INFO';
export type EXAM_INFO = typeof EXAM_INFO;

export const EXAM_USER_INFO = '/EXAM_USER_INFO';
export type EXAM_USER_INFO = typeof EXAM_USER_INFO;

export const EXAM_SUBJECT_LIST = '/EXAM_SUBJECT_LIST';
export type EXAM_SUBJECT_LIST = typeof EXAM_SUBJECT_LIST;

export const EXAM_SUBJECT_LIST_ACTVIE = '/EXAM_SUBJECT_LIST_ACTVIE';
export type EXAM_SUBJECT_LIST_ACTVIE = typeof EXAM_SUBJECT_LIST_ACTVIE;

export const EXAM_DETAIL_INFO = '/EXAM_DETAIL_INFO';
export type EXAM_DETAIL_INFO = typeof EXAM_DETAIL_INFO;

export const EXAM_WELCOME_INFO = '/EXAM_WELCOME_INFO';
export type EXAM_WELCOME_INFO = typeof EXAM_WELCOME_INFO;

export const SUBJECT_EVAL = '/SUBJECT_EVAL';
export type SUBJECT_EVAL = typeof SUBJECT_EVAL;

export const CACHE_USER_CODE = '/CACHE_USER_CODE';
export type CACHE_USER_CODE = typeof CACHE_USER_CODE;

export const CACHE_GAME_FRAGMENT_CODE_ARRAY = '/CACHE_GAME_FRAGMENT_CODE_ARRAY';
export type CACHE_GAME_FRAGMENT_CODE_ARRAY = typeof CACHE_GAME_FRAGMENT_CODE_ARRAY;

export const CACHE_GAME_ANSWER_CODE = '/CACHE_GAME_ANSWER_CODE';
export type CACHE_GAME_ANSWER_CODE = typeof CACHE_GAME_ANSWER_CODE;

export const CONTROL_DEC_RUN_GAME_VISIBLE = '/CONTROL_DEC_RUN_GAME_VISIBLE';
export type CONTROL_DEC_RUN_GAME_VISIBLE = typeof CONTROL_DEC_RUN_GAME_VISIBLE;

export const CACHE_PROBLEM_STATUS = '/CACHE_PROBLEM_STATUS';
export type CACHE_PROBLEM_STATUS = typeof CACHE_PROBLEM_STATUS;

export const CACHE_PROBLEM_RESULT = '/CACHE_PROBLEM_RESULT';
export type CACHE_PROBLEM_RESULT = typeof CACHE_PROBLEM_RESULT;

export const CACHE_FILE_ACTIVE_KEY = '/CACHE_FILE_ACTIVE_KEY';
export type CACHE_FILE_ACTIVE_KEY = typeof CACHE_FILE_ACTIVE_KEY;

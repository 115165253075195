/** 头像组件 */
import React, { FC, useState, useEffect } from 'react';
import Avatar from 'antd/lib/avatar';
import headDefault from '@/assets/images/evaluation/head-default@2x.jpg';
interface AvatarProps {
  nickname?: any; // 昵称
  size?: number; // 头像大小
  src?: any; // 头像地址
  srcSet?: any; // 头像
  shape?: 'circle'; // 头像形状
  style?: any; // 头像样式
  icon?: any; // 头像默认图标
}
// 头像优先级属性 src > icon > nickname
const CustomAvatar: FC<AvatarProps> = props => {
  const { size, src, srcSet, shape, style, icon } = props;

  const [avatarSrc, setAvatarSrc] = useState(headDefault);

  useEffect(() => {
    src && setAvatarSrc(src);
  }, [src]);

  const handleError = () => {
    setAvatarSrc(headDefault);
    return false;
  };

  return (
    <Avatar
      style={style ? { ...style } : {}}
      size={size ? size : 32}
      src={avatarSrc}
      icon={icon}
      srcSet={srcSet}
      shape={shape}
      onError={() => handleError()}
    ></Avatar>
  );
};

export default CustomAvatar;

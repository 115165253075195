// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".PCMobileLayout_pc-mobile-layout-wrap__u4DpH{width:100%;height:100%}", "",{"version":3,"sources":["D:\\文件\\companyProject\\examNew\\ddcode-exam/src\\layouts\\PCMobileLayout\\PCMobileLayout.module.scss"],"names":[],"mappings":"AAGA,6CACE,UAAA,CACA,WAAA","file":"PCMobileLayout.module.scss","sourcesContent":["@import '../../styles/variables.scss';\n@import '../../styles/mixin.scss';\n\n.pc-mobile-layout-wrap {\n  width: 100%;\n  height: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"pc-mobile-layout-wrap": "PCMobileLayout_pc-mobile-layout-wrap__u4DpH"
};
module.exports = exports;

import Axios from '../config/axios';
import { apiFragment } from '@/api/config/base';

// -------------------------------报名----------------------------
//获取验证码
export const getSendSms = async (mobile: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/signup/sendSms?mobile=${mobile}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//登录
export const postSignup = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/signup/check`,
      data,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//考试相关说明
export const getExamInfo = async (id: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/signup/examInfo/${id}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//获取考生信息
export const getExamUserInfo = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/signup/getExamUserInfo`,
      data,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//提交选择考场
export const postChooseRoom = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/signup/chooseRoom`,
      data,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//按键检测
export const getButtonType = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/signup/checkButton?buttonType=${data.buttonType}&examId=${data.examId}&mobile=${data.mobile}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//成绩

export const postUserResults = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/signup/getExamUserResults`,
      data,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//选择地区

export const getRegion = async (data: any) => {
  try {
    return await Axios.request({
      url:
        data && data.parentId
          ? `${apiFragment.ddcExam}/common/region?parentId=${data.parentId}&type=${data.type}`
          : `${apiFragment.ddcExam}/common/region?type=${data.type}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// 保存邮寄信息
export const postExpressage = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/expressage/save`,
      data,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// 考试竞赛报名-补考
export const postSupplementary = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/signup/supplementary`,
      data,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// -------------------------------考试----------------------------
//登录
export const postLogin = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/exam/login`,
      data,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//获取考试信息
export const getExamInfoData = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/exam/examInfo?examId=${data.examId}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//题目列表
export const postSubjects = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/exam/subjects`,
      data,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//题目详情
export const getExamDetailInfo = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/exam/subject`,
      data,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//题目测评
export const postEval = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/exam/subject/eval`,
      data,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//题目保存
export const postSave = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/exam/subject/save`,
      data,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//考试提交
export const postSubmitPaper = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/exam/submitPaper`,
      data,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// //开始考试-校验信息
export const postValidate = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/exam/validate?examId=${data.examId}`,

      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};
//得到当前时间
export const getCurDate = async () => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/exam/getCurDate`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};


//pygame代码组装
export const postPygameEval = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcExam}/exam/subject/pygame/eval`,
      data,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};
import store from '@/store';
import { apiBaseUrl, apiFragment } from './base';
import { ResData } from './config';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosPromise, AxiosResponse } from 'axios'; // 引入axios和定义在node_modules/axios/index.ts文件里的类型声明
import { message } from 'antd';
import cookies from 'js-cookie';
import { logoutAction } from '@/store/login/action';
import { generateUUID } from '@/utils';
const sha1 = require('sha1');

const {
  api_base_url_ddc_edu,
  api_base_url_ddc_port,
  api_base_url_ddc_exam,
  api_base_url_ddc_customer,
  api_base_url_ddc_rating
} = apiBaseUrl;

// const apiBaseUrl: string = process.env.NODE_ENV === 'production' ? proApiBaseUrl : devApiBaseUrl; // process.env.NODE_ENV是vue服务内置的环境变量
interface baseConfig extends AxiosRequestConfig {
  spin?: boolean;
}

const defOptions: baseConfig = {
  baseURL: '',
  timeout: 15000,
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
};

const editBasUrl = function (config: any) {
  // 签名算法
  let _headers = [],
    signToken = '766a6afe879b44bd9c5c7518746bd0e4',
    timestamp = Date.now().toString(),
    nonce = generateUUID();
  _headers.push(signToken, timestamp, nonce);
  let shaStr = sha1(_headers.sort().join(''));
  // 接口请求的所有配置，都在这个config对象中，他的类型是AxiosRequestConfig，你可以看到他有哪些字段
  // 如果你要修改接口请求配置，需要修改 axios.defaults 上的字段值
  config.headers.timestamp = timestamp;
  config.headers.nonce = nonce;
  config.headers.signature = shaStr;

  const str = config.url.split('/');
  switch (str[0]) {
    case apiFragment.ddcEdu:
      config.baseURL = api_base_url_ddc_edu;
      break;
    case apiFragment.ddcPort:
      config.baseURL = api_base_url_ddc_port;
      break;
    case apiFragment.ddcBase:
      config.baseURL = api_base_url_ddc_edu;
      break;
    case apiFragment.ddcExam:
      config.baseURL = api_base_url_ddc_exam;
      break;
    case apiFragment.ddcCustomer:
      config.baseURL = api_base_url_ddc_customer;
      break;
    case apiFragment.ddcRating:
      config.baseURL = api_base_url_ddc_rating;
      break;
    default:
      break;
  }
};

//定义请求类
class HttpRequest {
  // 定义一个接口请求类，用于创建一个axios请求实例
  constructor(public baseUrl: string = '') {
    // 这个类接收一个字符串参数，是接口请求的基本路径
    this.baseUrl = baseUrl;
  }
  // 我们实际调用接口的时候调用实例的这个方法，他返回一个AxiosPromise
  public request(options: baseConfig): AxiosPromise<ResData> {
    const instance: AxiosInstance = axios.create(); // 这里使用axios.create方法创建一个axios实例，他是一个函数，同时这个函数包含多个

    if (options && options.url) {
      let _url = options.url.includes('localSubject/eval'); //判断是否是上传学生数据的接口，若是，则不要有timeout限制
      // console.log(69, options.url, _url);
      const _defOptions = {
        baseURL: '',
        timeout: 5000,
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: sessionStorage.getItem('token') || cookies.get('token') || null
        } //tokenEDU_TOKEN
      };
      if (_url) {
        // store.dispatch({ type: 'evaluation/CLICK_FLAG', value: false });
        options = this.mergeConfig(_defOptions, options);
      } else {
        //属性;
        options = this.mergeConfig(defOptions, options); // 合并基础路径和每个接口单独传入的配置，比如url、参数等
      }
    }

    this.interceptors(instance, options.url); // 调用interceptors方法使拦截器生效
    return instance(options); // 最后返回AxiosPromise
  }
  // 定义这个函数用于添加全局请求和响应拦截逻辑
  private interceptors(instance: AxiosInstance, url?: string) {
    // 在这里添加请求拦截
    instance.interceptors.request.use(
      (config: baseConfig) => {
        if (url?.indexOf('ddc-exam') !== -1) {
          config.headers.token = cookies.get('token'); //cookies.get('TOKEN');
        } else {
          config.headers.token = cookies.get('token'); //cookies.get('EXAM_TOKEN');
        }

        if (url?.indexOf('/rating/export') !== -1) {
          config.responseType = 'blob';
        } else {
          config.responseType = 'json';
        }

        // 接口请求的所有配置，都在这个config对象中，他的类型是AxiosRequestConfig，你可以看到他有哪些字段
        // 如果你要修改接口请求配置，需要修改 axios.defaults 上的字段值
        editBasUrl(config);
        if (!config.spin) {
          store.dispatch({ type: 'common/REQUEST_COMMON_SPIN', value: true });
        }
        return config;
      },
      (error: any) => {
        store.dispatch({ type: 'common/REQUEST_COMMON_SPIN', value: false });
        return Promise.reject(error);
      }
    );
    // 在这里添加响应拦截
    instance.interceptors.response.use(
      (res: AxiosResponse) => {
        const { data } = res; // res的类型是AxiosResponse<any>，包含六个字段，其中data是服务端返回的数据
        const { code, msg } = data; // 通常服务端会将响应状态码、提示信息、数据等放到返回的数据中
        if (code !== 0) {
          // 这里我们在服务端将正确返回的状态码标为0
          console.error(msg); // 如果不是0，则打印错误信息，我们后面讲到UI组件的时候，这里可以使用消息窗提示
          if (code === 36 || code === 35) {
            store.dispatch(logoutAction());
          }
        }
        store.dispatch({ type: 'common/REQUEST_COMMON_SPIN', value: false });
        return res; // 返回数据
      },
      (error: any) => {
        store.dispatch({ type: 'common/REQUEST_COMMON_SPIN', value: false });
        // 这里是遇到报错的回调
        if (error.response) {
          switch (error.response.status) {
            case 400:
              error.message = '错误请求';
              break;
            case 401:
              error.message = '未授权，请重新登录';
              break;
            case 403:
              error.message = '拒绝访问';
              break;
            case 404:
              error.message = '请求错误,未找到该资源';
              break;
            case 405:
              error.message = '请求方法未允许';
              break;
            case 408:
              error.message = '请求超时';
              break;
            case 500:
              error.message = '服务连接失败';
              break;
            case 501:
              error.message = '网络未实现';
              break;
            case 502:
              error.message = '网络错误';
              break;
            case 503:
              error.message = '服务不可用';
              break;
            case 504:
              error.message = '网络超时';
              break;
            case 505:
              error.message = 'http版本不支持该请求';
              break;
            default:
              error.message = `连接错误${error.response.status}`;
          }
          message.error(error.message);
        } else {
          message.error('服务器未响应');
        }
        return Promise.reject(error);
      }
    );
  }
  private mergeConfig(defOptions: baseConfig, options: baseConfig): baseConfig {
    // 这个方法用于合并基础路径配置和接口单独配置
    return Object.assign(defOptions, options);
  }
}

export default new HttpRequest();

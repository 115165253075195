import React from 'react';
import classNames from 'classnames';
import IconFont from '@/components/IconFont';
import qrcode from '@/assets/images/evaluation/二维码@2x .png';
import styles from './index.module.scss';
const Footer = () => {
  return (
    <div className={classNames(styles['footer-wrap'])}>
      <div className={classNames(styles['footer-inner'])}>
        <ul>
          <li>
            <h3>相关产品</h3>
            <div>
              <a
                href="https://edu.dingdangcode.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  outline: 'none',
                  background: 'none',
                  textDecoration: 'none',
                  color: ' rgba(0, 0, 0, 0.85)'
                }}
              >
                叮当码校园
              </a>
            </div>
          </li>
          <li>
            <h3>关于我们</h3>
            <div>
              <a
                href="https://www.zscloudlink.com/aboutus"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  outline: 'none',
                  background: 'none',
                  textDecoration: 'none',
                  color: ' rgba(0, 0, 0, 0.85)'
                }}
              >
                公司简介
              </a>
            </div>
            <div>
              <a
                href="https://www.zscloudlink.com/aboutus"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  outline: 'none',
                  background: 'none',
                  textDecoration: 'none',
                  color: ' rgba(0, 0, 0, 0.85)'
                }}
              >
                资质与荣誉
              </a>
            </div>
            <div>
              <a
                href="https://www.zscloudlink.com/aboutus"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  outline: 'none',
                  background: 'none',
                  textDecoration: 'none',
                  color: ' rgba(0, 0, 0, 0.85)'
                }}
              >
                加入我们
              </a>
            </div>
          </li>
          <li>
            <h3>联系我们</h3>
            <div>电话：028-61318891</div>
            <div>邮箱：zsyl@zscloudlink.com</div>
          </li>
          <li>
            <h3>关注我们</h3>
            <span>
              <IconFont type="iconwechat" style={{ color: '#7ED321', fontSize: 16 }} />
            </span>
            <span>
              <img width="76" height="76" src={qrcode} alt="" />
            </span>
          </li>
        </ul>
        <div className={classNames(styles['copy-right'])}>
          Copyright ©2018 中森云链（成都）科技有限责任公司 保留所有权&nbsp;
          <a
            href="http://beian.miit.gov.cn/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              outline: 'none',
              background: 'none',
              textDecoration: 'none',
              color: ' rgba(0, 0, 0, 0.85)'
            }}
          >
            蜀ICP备18005333号
          </a>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002002024"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              outline: 'none',
              background: 'none',
              textDecoration: 'none',
              color: ' rgba(0, 0, 0, 0.85)'
            }}
          >
            川公网安备 51019002002024号
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

const notFound = () => {
  return (
    <div className="notfound-wrap">
      <span>网页丢失了...</span>
      <div className="nav">
        <Link to="/signup" className="item">
          报名
        </Link>
        <Link to="/exam" className="item">
          考试
        </Link>
      </div>
    </div>
  );
};

export default notFound;

import * as constants from './constants';

const loginState = {
  spin: false,
  loadingType: false
};
type loginState = typeof loginState;

/* 暴露请求返回的数据 */
const loginReducer = (state = loginState, action: { [type: string]: any }) => {
  switch (action.type) {
    case constants.SPIN:
      return { ...state, spin: action.value };
    case 'common/REQUEST_COMMON_SPIN':
      return { ...state, loadingType: action.value };
    default:
      return state;
  }
};

export default loginReducer;

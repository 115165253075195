import React from 'react';
import { withRouter, BrowserRouter } from 'react-router-dom';
import routes from '@/routes/config';
import renderRoutes from '@/routes/index';
// import { Spin } from 'antd';
// import { LoadingOutlined } from '@ant-design/icons';
// import { useSelector } from 'react-redux';
const App = () => {
  // const { loadingType } = useSelector((state: any) => {
  //   return state.commonReducer;
  // });
  // const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    // <Spin
    //   spinning={loadingType}
    //   tip={'加载中...'}
    //   indicator={antIcon}
    //   wrapperClassName="loading-wrap"
    // >
    <div id="App">
      <BrowserRouter>{renderRoutes(routes)}</BrowserRouter>
    </div>
    // </Spin>
  );
};

export default withRouter(App);

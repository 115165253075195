/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/anchor-is-valid */
/** user-info */
import React, { FC, useState, useEffect } from 'react';
import { Dropdown } from 'antd';
import Avatar from '@/components/evaluation/customAvatar';
// import IconFont from '@/components/IconFont';
import './userInfo.scss';
import { useSelector, shallowEqual } from 'react-redux';
// import { fetchLoginOut } from '@store/login/action';
import { lastestDynamics } from '@/api/controller/apiEvaluation';
// import CustomEmpty from '@/components/evaluation/customEmpty';
import { removeUserInfoAction } from '@/store/evaluation/action';
import { useDispatch } from 'react-redux';
import SchoolExchange from '../schoolExchange';
import Cookies from 'js-cookie';
import schImg from '@/assets/images/evaluation/icon_transform.svg';
import setImg from '@/assets/images/evaluation/icon_setting.svg';
import exitImg from '@/assets/images/evaluation/icon_exit.svg';
const UserInfo = () => {
  return (
    <aside className="aside flex flex-align-center">
      <AsideUserInfo />
    </aside>
  );
};

const AsideUserInfo: FC = () => {
  const {
    dynamics = '',
    userId = null,
    portraitUrl = '',
    nickname = ''
    // unreadMessageNumbers = ''
  } = useInitialData();
  const { userInfo } = useSelector((state: any) => state.evaluationReducer, shallowEqual);
  const toLogin = (e: any) => {
    e.preventDefault();
    // window.location.href =
    //   process.env.REACT_APP_ENV === 'production'
    //     ? 'https://vip.dingdangcode.com/login?redirectUrl=https://exam.dingdangcode.com'
    //     : 'https://vip.dingdangcode.cn/login?redirectUrl=https://exam.dingdangcode.cn';
    window.location.href =
      process.env.REACT_APP_ENV === 'production'
        ? 'https://edu.dingdangcode.com/login?redirectUrl=https://exam.dingdangcode.com'
        : 'https://edu.dev.dingdangcode.cn/login?redirectUrl=https://exam.dingdangcode.cn';
    // 'http://localhost:8080/login?redirectUrl=http://localhost:3000/';
  };
  // useEffect(() => {
  //   if (userInfo && userInfo.id && userInfo.id !== 'null') {
  //     console.log(51111, userInfo && userInfo.id);
  //     window.location.href =
  //       process.env.REACT_APP_ENV === 'production'
  //         ? 'https://exam.dingdangcode.com'
  //         : // : 'https://exam.dingdangcode.cn'
  //           'localhost:3000/';
  //   } else {
  //     console.log(5222, userInfo && userInfo.id);
  //     window.location.href =
  //       process.env.REACT_APP_ENV === 'production'
  //         ? 'https://edu.dingdangcode.com/login?redirectUrl=https://exam.dingdangcode.com'
  //         : //  : 'https://edu.dev.dingdangcode.cn/login?redirectUrl=https://exam.dingdangcode.cn'
  //           'http://localhost:8080/login?redirectUrl=http://localhost:3000/';
  //     // let hidden = document.getElementsByClassName('hidden')[0] as HTMLElement;
  //     // // hidden.click();
  //   }
  // }, [userInfo]);

  console.log(53333, userInfo && userInfo.id);
  return (
    <div className="user-info flex flex-pack-end">
      {userId ? (
        <Dropdown
          overlay={<Menu dynamics={dynamics} />}
          trigger={['hover']}
          placement="bottomRight"
          getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
        >
          <div className="avatar pointer flex flex-align-center" onClick={e => e.preventDefault()}>
            {/* <Badge dot={unreadMessageNumbers ? true : false}>
              <Avatar size={32} nickname={nickname} src={portraitUrl} />
            </Badge> */}
            <Avatar size={32} nickname={nickname} src={portraitUrl} />
          </div>
        </Dropdown>
      ) : (
        <span className="noLogin pointer" onClick={toLogin}>
          <span>登录</span>
        </span>
      )}
      {/* {userId ? null : (
        <a
          href={
            process.env.REACT_APP_ENV === 'production'
              ? `https://edu.dingdangcode.com/login?redirectUrl=${encodeURIComponent(
                  'https://exam.dingdangcode.com/'
                )}`
              : `http://localhost:8080/login?redirectUrl=${encodeURIComponent(
                  'http://localhost:3000/'
                )}`
          }
          // `http://localhost:8080/login?redirectUrl=${encodeURIComponent(
          //         'http://localhost:3000/'
          //       )}`
          target="_parent"
          style={{ display: 'none' }}
          id="loginId"
          className={'hidden'}
        >
          登录
        </a>
      )} */}
    </div>
  );
};

const Menu = (props: any) => {
  const dispatch = useDispatch();
  const { userInfo, roles, schoolName, schoolId, schoolOptions } = useSelector(
    (state: any) => state.evaluationReducer,
    shallowEqual
  );
  const [modalVisible, setmodalVisible] = useState(false);
  /* 前往个人资料 */
  const toProfile = () => {
    // window.location.href =
    //   process.env.REACT_APP_ENV === 'production'
    //     ? 'https://vip.dingdangcode.com/personal/profile'
    //     : 'https://vip.dingdangcode.cn/personal/profile';
    window.location.href =
      process.env.REACT_APP_ENV === 'production'
        ? 'https://edu.dingdangcode.com/school/set'
        : 'https://edu.dev.dingdangcode.cn/school/set';
  };

  /* 前往个人中心 */
  // const toPersonal = () => {
  //   window.location.href =
  //     process.env.REACT_APP_ENV === 'production'
  //       ? 'https://vip.dingdangcode.com/personal/study'
  //       : 'https://vip.dingdangcode.cn/personal/study';
  // };

  /* 前往消息中心 */
  // const toMessage = () => {
  //   window.location.href =
  //     process.env.REACT_APP_ENV === 'production'
  //       ? 'https://vip.dingdangcode.com/message'
  //       : 'https://vip.dingdangcode.cn/message';
  // };

  const handleLoginOut = () => {
    console.log('process.env.REACT_APP_ENV', process.env.REACT_APP_ENV);
    Cookies.remove('token', {
      domain:
        process.env.REACT_APP_ENV === 'production'
          ? '.dingdangcode.com'
          : process.env.REACT_APP_ENV === 'development'
          ? '.dingdangcode.cn'
          : 'localhost'
    });
    let data = Cookies.get();
    for (let p2 in data) {
      Cookies.remove(`${p2}`);
    }
    // domainToken('token').remove();
    sessionStorage.removeItem('token');
    dispatch(removeUserInfoAction({}));
    window.location.replace('/');
  };
  const handleSet = () => {
    window.location.href =
      process.env.REACT_APP_ENV === 'production'
        ? 'https://edu.dingdangcode.com/school/set'
        : 'https://edu.dev.dingdangcode.cn/school/set';
  };
  const handleClick = (value: any) => {
    setmodalVisible(value);
  };
  return (
    <div className="menu-list">
      <div className="ml-top flex flex-align-center flex-pack-justify">
        <div className="mlt-info">
          <span onClick={toProfile} className="pointer">
            <Avatar
              size={40}
              nickname={userInfo.nickname}
              src={userInfo.portraitUrl}
              style={{ minWidth: 40 }}
            />
          </span>
          <span
            className="elli_1"
            style={{ lineHeight: 1.3, width: '100%', textAlign: 'center', marginTop: '8px' }}
          >
            {decodeURIComponent(userInfo.nickname)}
          </span>
          <span
            className="elli_1"
            style={{
              lineHeight: 1.3,
              width: '100%',
              textAlign: 'center',
              marginTop: '4px',
              marginBottom: '12px',
              color: 'rgba(0,0,0,0.45)'
            }}
          >
            {roles.includes(2) ? '学生' : '教师'}
          </span>
          <span className="elli_1">{schoolName}</span>
        </div>
        {/* <div className="mlt-message pointer" onClick={toMessage}>
          <Badge count={userInfo.unreadMessageNumbers} overflowCount={99} className="mlt-badge">
            <IconFont type="iconsettings1" style={{ fontSize: 20 }} />
          </Badge>
        </div> */}
      </div>
      {/* <div className="ml-middle">
        <div className="mlm-title">
          <span>最近学习</span>
        </div>
        {dynamics.length > 0 ? (
          <ul className="mlm-list">
            {dynamics.map((x: any, index: number) => (
              <li key={index} className="mlm-list-cell flex flex-align-center flex-pack-justify">
                <span className="elli_1">{x.targetName}</span>
                <div onClick={() => switchLink(x)}>
                  <a target="_blank" rel="noopener noreferrer" className="mlm-goon">
                    继续学习
                  </a>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <CustomEmpty style={{ width: 100, margin: '0 auto' }} secondaryDescription="" />
        )}
      </div> */}
      <div className="ml-set">
        <div
          onClick={() => setmodalVisible(true)}
          className="flex flex-pack-center flex-align-top "
          style={{ marginLeft: '-10px' }}
        >
          {/* <IconFont type="iconexc" style={{ fontSize: 20, verticalAlign: -4, marginRight: 2 }} /> */}
          <img src={schImg} alt="" />
          <span style={{ lineHeight: '36px' }}>切换学校</span>
        </div>
        <div
          onClick={handleSet}
          className="flex flex-pack-center flex-align-top "
          style={{ marginLeft: '-10px' }}
        >
          {/* <IconFont type="iconexc" style={{ fontSize: 20, verticalAlign: -4, marginRight: 2 }} /> */}
          <img src={setImg} alt="" />
          <span style={{ lineHeight: '36px' }}>账号设置</span>
        </div>
      </div>
      <SchoolExchange
        visible={modalVisible}
        handleClick={handleClick}
        schoolId={schoolId}
        schoolsList={schoolOptions}
      ></SchoolExchange>
      <div className="ml-bottom">
        {/* <span className="flex-1" onClick={toPersonal}>
          <IconFont type="iconuser1" style={{ fontSize: 20, verticalAlign: -4, marginRight: 2 }} />
          <span>个人中心</span>
        </span> */}
        <span className="flex flex-align-center flex-pack-center" onClick={handleLoginOut}>
          {/* <IconFont type="iconexc" style={{ fontSize: 20, verticalAlign: -4, marginRight: 2 }} /> */}
          <img src={exitImg} alt="" />
          <span style={{ lineHeight: '34px', height: '36px' }}>退出登录</span>
        </span>
      </div>
    </div>
  );
};

/**
 * @param {} x.targetType	integer($int32) 1课程 2作业 3作品 4项目
 */
// function switchLink(x: any) {
//   console.log('switchLink', x);
//   let link = '';
//   switch (x.targetType) {
//     case 1:
//       link =
//         process.env.REACT_APP_ENV === 'production'
//           ? `https://vip.dingdangcode.com/learning/classroom/introduction?id=${x.targetId}`
//           : `https://vip.dingdangcode.cn/learning/classroom/introduction?id=${x.targetId}`;
//       break;
//     case 2:
//       link =
//         process.env.REACT_APP_ENV === 'production'
//           ? `https://vip.dingdangcode.com/learning?query=0&hwId=${x.targetId}&id=20`
//           : `https://vip.dingdangcode.cn/learning?query=0&hwId=${x.targetId}&id=20`;
//       break;
//     case 3:
//       break;
//     case 4:
//       link =
//         process.env.REACT_APP_ENV === 'production'
//           ? `https://vip.dingdangcode.com/learning/projectLearning?id=${x.targetId}`
//           : `https://vip.dingdangcode.cn/learning/projectLearning?id=${x.targetId}`;
//       break;
//     default:
//       break;
//   }
//   return (window.location.href = link);
// }

const useInitialData = () => {
  const [dynamics, setDynamics] = useState([]);

  const { userInfo } = useSelector((state: any) => state.evaluationReducer, shallowEqual);
  useEffect(() => {
    // 判断用户id 是否存在
    if (userInfo && userInfo.id) {
      lastestDynamics({ limit: 3 })
        .then(({ data }) => {
          if (data.data) {
            // targetType	integer($int32) 1课程2作业3作品4项目
            setDynamics(data.data);
          }
        })
        .catch();
    }
  }, [userInfo]);

  return {
    dynamics,
    userId: userInfo && userInfo.id,
    portraitUrl: userInfo && userInfo.portraitUrl,
    nickname: userInfo && userInfo.nickname,
    unreadMessageNumbers: userInfo && userInfo.unreadMessageNumbers
  };
};

export default UserInfo;

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import loginReducer from './login/loginReducer';
import userReducer from './user/userReducer';
import commonReducer from './common/commonReducer';
import examReducer from '@/store/exam/examReducer';
import evaluationReducer from '@/store/evaluation/evaluationReducer';

const reducers = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    loginReducer,
    userReducer,
    commonReducer,
    examReducer,
    evaluationReducer
  });
export default reducers;

import Axios from '../config/axios';
import { apiFragment } from '@/api/config/base';
import { buildGET } from '@/utils/index';
const interfaceName = 'user';

export const getUserInfo = async () => {
  try {
    return await Axios.request({
      // url: `${apiFragment.ddcRating}/user/info`,
      url: `${apiFragment.ddcEdu}/user/userinfo`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

export const getPaperSubjectList = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/paper/self/detail?paperId=${data.paperId}&ratingId=${data.ratingId}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//得到当前时间
export const getCurDate = async () => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/common/currentDate`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//单个题目详情-C
export const getItemSubject = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/localSubject/${data.id}?showAnswer=${data.showAnswer}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};
//单个题目详情-B
export const getItemSubjectForEdu = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcEdu}/common/subject/${data.id}`, ///${data.subjectType}
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//题目测评
export const postEval = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/localSubject/eval`,
      data,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};
//题目保存记录
export const postSave = async (data: any, id: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/paper/self/do/subject?user=${id}`,
      data,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//题目保存记录
export const postEvent = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/paper/self/event?event=${data.event}&paperId=${data.paperId}&ratingId=${data.ratingId}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//试卷列表
export const examList = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/paper/page`,
      data,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//试卷列表保存草稿
export const saveSubjectsDraf = async (ratingId: number, data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcEdu}/rating/save/subjects/${ratingId}`,
      data: { paperId: data },
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// 最近学习
export const lastestDynamics = async (data: any) => {
  const url = await buildGET(`${apiFragment.ddcCustomer}/user/dynamics`, data);
  try {
    return await Axios.request({
      url,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};
//得到用户做题详情
export const getUserCode = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/localSubject/usercode/${data.ratingId}/${data.paperId}/${data.subjectId}/${data.userId}/${data.role}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// 考试管理-考生选择树
export const ratingExaminee = async () => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcEdu}/rating/examinee`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};
//试卷信息
export const getPaperInfo = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/rating/info/${data.ratingId}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// 考试管理-考生学生列表
export const commonUsers = async (data: any) => {
  const url = await buildGET(`${apiFragment.ddcEdu}/common/users`, data);
  try {
    return await Axios.request({
      url,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// 班级列表查询用户列;
export const commonStudentClazzs = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcEdu}/common/student/clazzs`,
      data,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//试卷预览详情
export const getPreviewPaperSubjectList = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/paper/info/${data.paperId}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// 考试管理 - 保存基本信息
export const ratingSave = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcEdu}/rating/save`,
      method: 'POST',
      data
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// 考试管理 - 修改基本信息

export const ratingUpdate = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcEdu}/rating/update`,
      method: 'POST',
      data
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// 考试管理 - 考试发布;
export const ratingPublish = async (ratingId: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/rating/publish/${ratingId}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//查询用户试卷详情
export const getUserPaperDetail = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/paper/user/detail?paperId=${data.paperId}&ratingId=${data.ratingId}&userId=${data.userId}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//查询考试详情回显
export const getRatingInfo = async (ratingId: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcEdu}/rating/info/${ratingId}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//pygame评测
export const postEvalPygame = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/localSubject/evalPygame`,
      data,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};
//pygame评测

//试卷列表
export const ratePageEve = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/rating/page`,
      method: 'POST',
      data
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//成绩列表
export const rateStatisEve = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcEdu}/rating/statistics`,
      method: 'POST',
      data
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//考试管理-B端权限判断
export const rateRolecheckEve = async () => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcEdu}/rating/roleCheck`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//考试删除
export const rateDeleteEve = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/rating/delete/${data}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//自己试卷状态
export const selfPaperEve = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/paper/self/paper/${data.ratingId}/${data.paperId}/`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//获取信息
export const userMessageEve = async () => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/user/message`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//已读消息
export const userMessageReadEve = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/user/message/read`,
      method: 'POST',
      data
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//导出试卷
export const ratingExportEve = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcEdu}/rating/export`,
      method: 'POST',
      data
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};
// ---------二期手动组卷------------
//组卷-系列范围
export const getSeries = async () => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcEdu}/rating/series`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};
//组卷-题目列表
export const postSubjects = async (data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcEdu}/rating/subjects`,
      method: 'POST',
      data
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//组卷-保存草稿
export const postSaveSubjects = async (ratingId: any, data: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcEdu}/rating/save/subjects/${ratingId}`,
      method: 'POST',
      data
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//组卷-编辑时的回显
export const getPaperSubjectInfo = async (ratingId: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/rating/paperSubjectInfo/${ratingId}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//组卷-编辑时的回显
export const schoolSwitch = async (schoolId: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcEdu}/${interfaceName}/switch/school/${schoolId}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//违规记录
export const breakRule = async (ratingId: any, userId: any) => {
  try {
    return await Axios.request({
      url: `${apiFragment.ddcRating}/rating/breakRule/${ratingId}/${userId}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

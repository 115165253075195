/* eslint-disable-next-line */
export const CHANGE_USERNAME = 'login/CHANGE_USERNAME';
export type CHANGE_USERNAME = typeof CHANGE_USERNAME;

export const CHANGE_PASSWORD = 'login/CHANGE_PASSWORD';
export type CHANGE_PASSWORD = typeof CHANGE_PASSWORD;

export const CHANGE_REMBERME = 'login/CHANGE_REMBERME';
export type CHANGE_REMBERME = typeof CHANGE_REMBERME;

export const LOGIN = 'login/LOGIN';
export type LOGIN = typeof LOGIN;

export const LOGOUT = 'login/LOGOUT';
export type LOGOUT = typeof LOGOUT;

import React, { useLayoutEffect } from 'react';
import classNames from 'classnames';
import Header from '@/components/evaluation/Header';
import Footer from '@/components/evaluation/Footer';
import styles from './EvaluationLayout.module.scss';
import { requestUserInfo } from '@/store/evaluation/action';
import { useDispatch } from 'react-redux';

const EvaluationLayout = function (props: any) {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(requestUserInfo());
  }, [dispatch]);
  return (
    <div className={classNames(styles['evaluation-layout-wrap'])}>
      <header className={classNames(styles['evaluation-layout-header'])}>
        <Header></Header>
      </header>
      <section className={classNames(styles['evaluation-layout-section'])}>
        {props.children}
      </section>
      <footer className={classNames(styles['evaluation-layout-footer'])}>
        <Footer></Footer>
      </footer>
    </div>
  );
};

export default EvaluationLayout;

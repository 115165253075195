import { IapiFragment } from './config';
let BASE_URL_DDC_EDU_ENV = '',
  BASE_URL_DDC_PORT_ENV = '',
  BASE_URL_DDC_EXAM_ENV = '',
  BASE_URL_DDC_RATING_ENV = '',
  BASE_URL_DDC_CUSTOMER_ENV = '',
  ddcEdu = 'ddc-edu3',
  ddcPort = 'ddc-port',
  ddcBase = 'ddc-base',
  ddcExam = 'ddc-exam',
  ddcCustomer = 'ddc-customer',
  ddcRating = 'ddc-rating';
if (process.env.REACT_APP_ENV === 'production') {
  BASE_URL_DDC_EDU_ENV = 'https://api.dingdangcode.com/';
  BASE_URL_DDC_PORT_ENV = 'https://api.dingdangcode.com/';
  BASE_URL_DDC_EXAM_ENV = 'https://api.dingdangcode.com/';
  BASE_URL_DDC_CUSTOMER_ENV = 'https://api.dingdangcode.com/';
  BASE_URL_DDC_RATING_ENV = 'https://api.dingdangcode.com/';

  BASE_URL_DDC_RATING_ENV = 'https://api.dingdangcode.com/';
} else if (process.env.REACT_APP_ENV === 'development') {
  BASE_URL_DDC_EDU_ENV = 'https://api.dingdangcode.cn/';
  BASE_URL_DDC_PORT_ENV = 'https://api.dingdangcode.cn/';
  BASE_URL_DDC_EXAM_ENV = 'https://api.dingdangcode.cn/';
  BASE_URL_DDC_CUSTOMER_ENV = 'https://api.dingdangcode.cn/';
  BASE_URL_DDC_RATING_ENV = 'https://api.dingdangcode.cn/';
} else {
  BASE_URL_DDC_EDU_ENV = 'https://api.dingdangcode.cn/';
  BASE_URL_DDC_PORT_ENV = 'https://api.dingdangcode.cn/';
  BASE_URL_DDC_EXAM_ENV = 'https://api.dingdangcode.cn/';
  BASE_URL_DDC_CUSTOMER_ENV = 'https://api.dingdangcode.cn/';
  BASE_URL_DDC_RATING_ENV = 'https://api.dingdangcode.cn/';
  // BASE_URL_DDC_EDU_ENV = 'https://api.dingdangcode.com/'; //注释123：要还原上面的
  // BASE_URL_DDC_PORT_ENV = 'https://api.dingdangcode.com/';
  // BASE_URL_DDC_EXAM_ENV = 'https://api.dingdangcode.com/';
  // BASE_URL_DDC_CUSTOMER_ENV = 'https://api.dingdangcode.com/';
  // BASE_URL_DDC_RATING_ENV = 'https://api.dingdangcode.com/';
}
// api基础域名
export const apiBaseUrl = {
  api_base_url_ddc_edu: BASE_URL_DDC_EDU_ENV,
  api_base_url_ddc_port: BASE_URL_DDC_PORT_ENV,
  api_base_url_ddc_exam: BASE_URL_DDC_EXAM_ENV,
  api_base_url_ddc_customer: BASE_URL_DDC_CUSTOMER_ENV,
  api_base_url_ddc_rating: BASE_URL_DDC_RATING_ENV
};

// api微服务区分标识
export const apiFragment: IapiFragment = {
  ddcEdu,
  ddcPort,
  ddcBase,
  ddcExam,
  ddcCustomer,
  ddcRating
};

/* eslint-disable-next-line */
export const EVALUATION_USER_INFO = '/EVALUATION_USER_INFO';
export type EVALUATION_USER_INFO = typeof EVALUATION_USER_INFO;
// 删除用户信息
export const REMOVE_EVALUATION_USER_INFO = '/REMOVE_EVALUATION_USER_INFO';
export type REMOVE_EVALUATION_USER_INFO = typeof REMOVE_EVALUATION_USER_INFO;

export const CACHE_USER_CODE = 'evaluation/CACHE_USER_CODE';
export type CACHE_USER_CODE = typeof CACHE_USER_CODE;

export const CACHE_GAME_FRAGMENT_CODE_ARRAY = 'evaluation/CACHE_GAME_FRAGMENT_CODE_ARRAY';
export type CACHE_GAME_FRAGMENT_CODE_ARRAY = typeof CACHE_GAME_FRAGMENT_CODE_ARRAY;

export const PAPER_SUBJECT_LIST = 'evaluation/PAPER_SUBJECT_LIST';
export type PAPER_SUBJECT_LIST = typeof PAPER_SUBJECT_LIST;

export const PAPER_SUBJECT_LIST_ACTVIE = 'evaluation/PAPER_SUBJECT_LIST_ACTVIE';
export type PAPER_SUBJECT_LIST_ACTVIE = typeof PAPER_SUBJECT_LIST_ACTVIE;

export const PAPER_ITEM_SUBJECT_DETAIL = 'evaluation/PAPER_ITEM_SUBJECT_DETAIL';
export type PAPER_ITEM_SUBJECT_DETAIL = typeof PAPER_ITEM_SUBJECT_DETAIL;

export const PAPER_RATE_PAGE = 'evaluation/PAPER_RATE_PAGE';
export type PAPER_RATE_PAGE = typeof PAPER_RATE_PAGE;

export const PAPER_USERCODE_DETAIL = 'evaluation/PAPER_USERCODE_DETAIL';
export type PAPER_USERCODE_DETAIL = typeof PAPER_USERCODE_DETAIL;

export const PAPER_INFO = 'evaluation/PAPER_INFO';
export type PAPER_INFO = typeof PAPER_INFO;

export const PAPER_EVAL = 'evaluation/PAPER_EVAL';
export type PAPER_EVAL = typeof PAPER_EVAL;

export const PAPER_SAVE = 'evaluation/PAPER_SAVE';
export type PAPER_SAVE = typeof PAPER_SAVE;

export const PREVIEW_PAPER = 'evaluation/PREVIEW_PAPER';
export type PREVIEW_PAPER = typeof PREVIEW_PAPER;

export const RATE_STATIS = 'evaluation/RATE_STATIS';
export type RATE_STATIS = typeof RATE_STATIS;

export const USER_PAPER = 'evaluation/USER_PAPER';
export type USER_PAPER = typeof USER_PAPER;

export const CACHE_GAME_ANSWER_CODE = 'evaluation/CACHE_GAME_ANSWER_CODE';
export type CACHE_GAME_ANSWER_CODE = typeof CACHE_GAME_ANSWER_CODE;

export const CONTROL_ERROR_EVAL_VISIBLE = 'evaluation/CONTROL_ERROR_EVAL_VISIBLE';
export type CONTROL_ERROR_EVAL_VISIBLE = typeof CONTROL_ERROR_EVAL_VISIBLE;

export const RATE_DELETE = 'evaluation/RATE_DELETE';
export type RATE_DELETE = typeof RATE_DELETE;

export const NOW_TIME = 'evaluation/NOW_TIME';
export type NOW_TIME = typeof NOW_TIME;

export const USER_MESSAGE = 'evaluation/USER_MESSAGE';
export type USER_MESSAGE = typeof USER_MESSAGE;

export const BREAK_RULE = 'evaluation/BREAK_RULE';
export type BREAK_RULE = typeof BREAK_RULE;

import React, { useState } from 'react';
import styles from './index.module.scss';
import { Modal, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { fetchShoolSwitch } from '@/store/evaluation/action';

const { Option } = Select;

export interface SchoolExchangeProps {
  visible: boolean;
  handleClick: (e: any) => void;
  schoolId: any;
  schoolsList: any;
}

const SchoolExchange: React.FC<SchoolExchangeProps> = props => {
  const dispatch = useDispatch();
  const { visible, handleClick, schoolId, schoolsList } = props;

  /* 切换学校 */

  const [school, setSchool] = useState(schoolId);

  /* 切换选择学校 */

  const handleChange = (value: any) => {
    setSchool(value);
  };

  const handleClickSchool = () => {
    handleClick(false);
    dispatch(fetchShoolSwitch(school));
  };
  return (
    <Modal
      title="切换学校"
      className={styles['school-exchange-modal']}
      visible={visible}
      onOk={handleClickSchool}
      onCancel={() => handleClick(false)}
      width={470}
      centered={true}
    >
      <div className={styles['container']}>
        <Select value={school} onChange={handleChange} style={{ width: '100%' }}>
          {schoolsList.map((item: any, index: number) => {
            return (
              <Option value={item.id} key={index}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      </div>
    </Modal>
  );
};

export default SchoolExchange;

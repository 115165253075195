import * as constants from './constants';
import { login } from '@/api/controller/apiUser';

/* 登录action*/

export const loginAction = (value: any) => ({
  type: constants.LOGIN,
  value
});

/* 注销登出action*/

export const logoutAction = () => ({
  type: constants.LOGOUT
});

/*账号改变action*/

export const usernameAction = (value: any) => ({
  type: constants.CHANGE_USERNAME,
  value
});
/*记住密码action*/

export const remeberAction = (value: boolean) => ({
  type: constants.CHANGE_REMBERME,
  value
});

/* user:登录*/
export const loginSubmit = (payload: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: 'LOADING',
      value: true
    });
    let {
      data: { data }
    } = await login(payload);
    if (data && data.token) {
      const reData = { token: data.token, ...payload };

      dispatch(loginAction(reData));
    }
    dispatch({
      type: 'LOADING',
      value: false
    });
  };
};

import * as constants from './constants';

interface IuseInfo {
  email: string; //邮箱
  gender: number; //1男2女
  id: number; //用户id
  loginCount: number; //登录次数
  loginWay: number; //登录方式：1账号、 2手机、3邮箱、4QQ、 5微信公众号、6微信
  mobile: string; //电话
  nickname: string; //昵称
  portraitUrl: string; //头像url
  position: string; //职位名称、职业备注
  remark: string; //备注
  roles: any[]; //角色标识0:管理员1:老师2:学生
  studentNo: string; //学号
  thirds: any[]; //第三方账号集合
  unreadMessageNumbers: number; //未读消息数
  username: string; //账号
}

const evaluationState = {
  userInfo: {}, //用户信息
  roles: [], //【0】管理员;【1】教师;【2】学生,
  schoolOptions: [], //学校集合
  schoolId: null, //当前学校id
  schoolName: null, //当前学校名称
  paperInfo: null, //试卷信息
  editType: 1, // 操作题：C++:0;普通(python)-1| 海龟-2 | 游戏-4
  fileActiveKey: 0, //作业active状态为0
  problemInfo: null, //题目详情
  userFiles: [{ fileName: 'main', fileType: 'py', fileContent: '' }], //作业用户（合集）代码,【main文件必须有，切为主体】
  gameFragmentCodeArray: null, //【游戏题目】 用户输入代码array
  contentType: null, // 选择题：1 操作题：2,
  sideActiveKey: null, // 题目id & sidebar选中的key值
  decRunGameVisible: false, //详情运行前端游戏题目,
  gameAnswerCode: '', //【游戏题目】 标准答案（合集）代码，
  activeSubjectId: null, //考试页面当前题目Id
  paperSubjectListInfo: [], //考试页面左侧题目列表
  itemSubjectDetail: null, //单道题目的详情信息
  refresh: true, //控制刷新接口状态
  refreshBtn: false, //控制我的答案和标准答案之间的切换
  ratePageData: null, //首页考试列表
  userCodeDetail: null,
  sidebarVisible: false, //列表 显示|隐藏
  evalResult: null, //测评接口数据(错误详情)
  ratingStatisData: null, //成绩列表
  canEdit: true, //编辑器能否编辑的状态
  // roleCheck: false, //考试管理-B端权限判断
  rateDelete: false, //考试删除
  refreshPage: false, //考试列表刷新
  userCode: '',
  codeType: 1, //查询成绩的时候 1是考生答案  2是正确答案
  aceUserCode: '',
  aceTrueCode: '',
  nowTime: 0,
  clickFlag: false, //保存按钮的loading状态显示,
  errorEvalVisible: false, //报错modal
  ojResult: null,
  userMessageData: null, //消息
  breakRuleData: 0
};
type evaluationState = typeof evaluationState;

/* 暴露请求返回的数据 */
const evaluationReducer = (state = evaluationState, action: { [type: string]: any }) => {
  switch (action.type) {
    case constants.CACHE_USER_CODE:
      return { ...state, userFiles: action.value };
    case constants.CACHE_GAME_FRAGMENT_CODE_ARRAY:
      return { ...state, gameFragmentCodeArray: action.value };
    case constants.PAPER_SUBJECT_LIST:
      return { ...state, paperSubjectListInfo: action.value };
    case 'evaluation/SUBJECT_ID':
      return { ...state, activeSubjectId: action.value };
    case constants.EVALUATION_USER_INFO:
      return {
        ...state,
        userInfo: action.value,
        schoolId: action.value.currentSchool.id,
        schoolName: action.value.currentSchool.name,
        roles: action.value.currentSchool.roles ? action.value.currentSchool.roles : [],
        schoolOptions: action.value.schools
      };
    // case constants.PAPER_SUBJECT_LIST_ACTVIE:
    //   return { ...state, activeSubjectId: action.value };
    case constants.PAPER_ITEM_SUBJECT_DETAIL:
      return {
        ...state,
        itemSubjectDetail: action.value,
        aceTrueCode: action.value && action.value.answer ? action.value.answer : ''
      };
    case 'evaluation/REFRESH':
      return { ...state, refresh: !state.refresh };
    case 'evaluation/REFRESH_BTN':
      return { ...state, refreshBtn: !state.refreshBtn };
    case 'evaluation/CHANGE_BTN_TYPE':
      return { ...state, codeType: action.value };
    case 'evaluation/CAN_EDIT':
      return { ...state, canEdit: action.value };
    case constants.PAPER_RATE_PAGE:
      return { ...state, ratePageData: action.value };
    case constants.PAPER_USERCODE_DETAIL:
      return {
        ...state,
        userCodeDetail: action.value,
        aceUserCode: action.value ? action.value.userAnswer : null,
        ojResult: action.value ? action.value.result : null,
        evalResult: action.value ? action.value.resultJson : 'null'
      };
    case constants.PAPER_INFO:
      return { ...state, paperInfo: action.value };
    case constants.PAPER_EVAL:
      return { ...state, evalResult: action.value };
    case constants.PREVIEW_PAPER:
      return { ...state, paperSubjectListInfo: action.value };
    case constants.RATE_STATIS:
      return { ...state, ratingStatisData: action.value };
    case constants.USER_PAPER:
      return { ...state, paperSubjectListInfo: action.value };
    case constants.CACHE_GAME_ANSWER_CODE:
      return { ...state, gameAnswerCode: action.value };
    // case constants.RATE_ROLE_CHECK:
    //   return { ...state, roleCheck: action.value };
    case constants.RATE_DELETE:
      return { ...state, rateDelete: action.value };
    case 'evaluation/REFRESH_PAGE':
      return { ...state, refreshPage: !state.refreshPage };
    case 'evaluation/CLICK_FLAG':
      return { ...state, clickFlag: action.value };
    case 'evaluation/OJ_RESULT':
      return { ...state, ojResult: action.value };
    case constants.REMOVE_EVALUATION_USER_INFO:
      return { ...state, userInfo: action.value };
    case constants.NOW_TIME:
      return { ...state, nowTime: action.value };
    case constants.CONTROL_ERROR_EVAL_VISIBLE:
      return { ...state, errorEvalVisible: action.value };
    case constants.USER_MESSAGE:
      return { ...state, userMessageData: action.value };
    case constants.BREAK_RULE:
      return { ...state, breakRuleData: action.value };
    default:
      return state;
  }
};

export default evaluationReducer;

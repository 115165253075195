import * as constants from './constants';

const examState = {
  examInfo: null, //报考说明信息
  rooms: [], //考场
  userInfo: null,
  identityCard: null,
  name: null,
  examInfoData: null, //考试的信息
  subjectList: null, //考试题目列表
  detailInfo: null, //题目详情
  skRunResult: null,
  ojRunResult: null,
  refresh: false,
  activeSubjectId: null, //当前ID,
  showWorkingType: false, //welcome页面是否加载working组件状态
  isEval: false, //是否需要测评
  nowTime: null,
  userFiles: [{ fileName: 'main', fileType: 'py', fileContent: '' }], //作业用户（合集）代码,【main文件必须有，切为主体】
  gameFragmentCodeArray: null, //【游戏题目】 用户输入代码array
  gameAnswerCode: '', //【游戏题目】 标准答案（合集）代码
  decRunGameVisible: false, //详情运行前端游戏题目
  problemStatus: 0, // 题目0 未做 1 正确 -1 错误
  problemResult: null, //题目结果
  fileActiveKey: 0 //作业active状态为0
};
type examState = typeof examState;

/* 暴露请求返回的数据 */
const examReducer = (state = examState, action: { [type: string]: any }) => {
  switch (action.type) {
    case constants.EXAM_INFO:
      return {
        ...state,
        examInfo: action.value,
        rooms: action.value.rooms
      };
    case constants.EXAM_USER_INFO:
      console.log(action.value);
      return {
        ...state,
        userInfo: action.value,
        identityCard: action.value.identityCard,
        name: action.value.name
      };
    case constants.EXAM_SUBJECT_LIST:
      return {
        ...state,
        subjectList: action.value
      };

    case constants.EXAM_DETAIL_INFO:
      return {
        ...state,
        detailInfo: action.value
      };
    case constants.EXAM_WELCOME_INFO:
      return {
        ...state,
        examInfoData: action.value
      };
    case 'common/SK_RUN_RESULT':
      return {
        ...state,
        skRunResult: action.value
      };
    case 'exam/REFRESH':
      return { ...state, refresh: !state.refresh };
    case 'exam/SUBJECT_ID':
      return { ...state, detailInfo: null, activeSubjectId: action.value };
    case 'exam/WORKING_TYPE':
      return { ...state, showWorkingType: action.value };
    case constants.EXAM_SUBJECT_LIST_ACTVIE:
      return { ...state, activeSubjectId: action.value };
    case 'exam/IS_EVAL':
      return { ...state, isEval: action.value };

    case constants.SUBJECT_EVAL:
      return {
        ...state,
        ojRunResult: action.value.result
      };

    case constants.CACHE_USER_CODE:
      return { ...state, userFiles: action.value };
    case constants.CACHE_GAME_FRAGMENT_CODE_ARRAY:
      return { ...state, gameFragmentCodeArray: action.value };
    case constants.CACHE_GAME_ANSWER_CODE:
      return { ...state, gameAnswerCode: action.value };
    case constants.CONTROL_DEC_RUN_GAME_VISIBLE:
      return { ...state, decRunGameVisible: action.value };
    case constants.CACHE_PROBLEM_STATUS:
      return { ...state, problemStatus: action.value };
    case constants.CACHE_PROBLEM_RESULT:
      return { ...state, problemResult: action.value };
    case constants.CACHE_FILE_ACTIVE_KEY:
      return { ...state, fileActiveKey: action.value };
    default:
      return state;
  }
};

export default examReducer;

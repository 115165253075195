import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import UserInfo from './userInfo/UserInfo';
import img_logo from '@/assets/images/evaluation/logo_download_teacher@2x.png';
import useDefImg from '@/assets/images/evaluation/icon_help.svg';
import { winOpen } from '@/utils/index';
const Header = (props: any) => {
  const goDeta = () => {
    winOpen('https://ddcode.yuque.com/books/share/bc0a5558-e9f9-46db-9997-05ad4647704a?#');
  };
  return (
    <div className={classNames(styles['header-wrap'])}>
      <div className={classNames(styles['header-inner'])}>
        <a href="https://edu.dingdangcode.com/">
          <img
            className={classNames(styles['logo-img'])}
            width="197"
            // height="30"
            src={img_logo}
            alt="logo"
          />
        </a>
        <span className={classNames(styles['user-center'])}>
          <div className={classNames(styles['user-intro'])} onClick={goDeta}>
            <img src={useDefImg} alt="" />
            使用说明
          </div>
          <UserInfo></UserInfo>
        </span>
      </div>
    </div>
  );
};

export default Header;

import { createStore, applyMiddleware } from 'redux'; //  引入createStore方法
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducers from './reducers';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const store = createStore(
  reducers(history),
  composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
); // 创建数据存储仓库
export default store; //暴露出去

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".schoolExchange_school-exchange-modal__3NeDp .schoolExchange_container__2tg1y{width:100%}", "",{"version":3,"sources":["D:\\文件\\companyProject\\examNew\\ddcode-exam/src\\components\\evaluation\\Header\\schoolExchange\\index.module.scss"],"names":[],"mappings":"AAIE,8EACE,UAAA","file":"index.module.scss","sourcesContent":["@import '../../../../styles/variables.scss';\n@import '../../../../styles/mixin.scss';\n\n.school-exchange-modal {\n  .container {\n    width: 100%;\n  }\n}"]}]);
// Exports
exports.locals = {
	"school-exchange-modal": "schoolExchange_school-exchange-modal__3NeDp",
	"container": "schoolExchange_container__2tg1y"
};
module.exports = exports;

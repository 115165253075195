import React, { useLayoutEffect } from 'react';

import { requestUserInfo } from '@/store/evaluation/action';
import { useDispatch } from 'react-redux';

const ExamLayout = function (props: any) {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(requestUserInfo());
  }, [dispatch]);
  return <div className="examLayout-wrap">{props.children}</div>;
};

export default ExamLayout;

import * as constants from './constants';
import cookies from 'js-cookie';
import Message from 'antd/lib/message';
// import Modal from 'antd/lib/modal';

const loginState = {
  username: '',
  token: null,
  password: '',
  loading: false
};
type loginState = typeof loginState;

/* 暴露请求返回的数据 */
const loginReducer = (state = loginState, action: { [type: string]: any }) => {
  switch (action.type) {
    case constants.LOGIN:
      console.log('登录成功！', action.value);
      cookies.set('token', action.value.token);
      return {
        ...state,
        username: action.value.username,
        password: action.value.password,
        token: action.value.token
      };
    case 'LOADING':
      return { ...state, loading: action.value };
    case constants.LOGOUT:
      console.log('注销退出！');
      // cookies.remove('rolename');
      // cookies.remove('roleid');
      // cookies.remove('token');
      // cookies.remove('EXAM_TOKNE');
      Message.destroy();
      Message.warn('登录失效,请重新登录', 6);
      cookies.remove('token', {
        domain:
          process.env.REACT_APP_ENV === 'production'
            ? '.dingdangcode.com'
            : process.env.REACT_APP_ENV === 'development'
            ? '.dingdangcode.cn'
            : 'localhost'
      });
      let data = cookies.get();
      for (let p2 in data) {
        cookies.remove(`${p2}`);
      }
      // domainToken('token').remove();
      sessionStorage.removeItem('token');
      return { ...state };
    default:
      return state;
  }
};

export default loginReducer;

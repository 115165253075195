import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.less';
import './styles/normalize.css';
import './styles/global.scss';
import './styles/reantd.scss';
import App from './App';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';

import zh_CN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import imgEmptyState from '@/assets/images/evaluation/img-empty-state@2x.png';
// import Vconsole from 'vconsole';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store';
import * as serviceWorker from './serviceWorker';

import 'moment/locale/zh-cn';
moment.locale('zh-cn');

if (process.env.REACT_APP_ENV === 'production') {
  const script: any = document.createElement('script');
  script.src = 'https://v1.cnzz.com/z_stat.php?id=1279026710';
  script.language = 'JavaScript';
  document.body.appendChild(script);
}

const customizeRenderEmpty = () => (
  <div className="img-empty-state" style={{ textAlign: 'center' }}>
    <img src={imgEmptyState} width="120" height="auto" alt="" />
    <p>暂无数据</p>
  </div>
);
ReactDOM.render(
  <ConfigProvider locale={zh_CN} renderEmpty={customizeRenderEmpty}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

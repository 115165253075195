import React, { lazy, LazyExoticComponent } from 'react';
import EvaluationLayout from '@/layouts/EvaluationLayout/EvaluationLayout';
import EvaluationLayoutNoFooter from '@/layouts/EvaluationLayoutNoFooter/EvaluationLayout';
import PCMobileLayout from '@/layouts/PCMobileLayout/PCMobileLayout';
import ExamLayout from '@/layouts/ExamLayout';
import { device } from '@/utils/device';
const isDevice = device();
export interface MenuBase {
  path: string;
  name: string;
  exact?: boolean;
  strict?: string;
  render?: any;
  query?: string;
  auth?: string;
  /** 是否登录校验，true不进行校验（访客） */
  notVerifyAuth?: boolean;
  layout?: React.ReactElement | any;
  component?: React.ReactElement | LazyExoticComponent<any> | any;
  routes?: MenuBase[];
}
const routes: MenuBase[] = [
  {
    path: '/',
    name: '考试-叮当码人工智能教育',
    exact: true,
    layout: EvaluationLayout,
    component: lazy(() => import('@/pages/index')),
    notVerifyAuth: true
  },
  {
    path: '/signup',
    name: '人工智能教育教学能力认证考试',
    exact: true,
    component: lazy(() => import('@/pages/signup/index')),
    notVerifyAuth: true
  },
  {
    path: '/signup/choiceexam',
    name: '人工智能教育教学能力认证考试',
    exact: true,
    component: lazy(() => import('@/pages/signup/choiceExam')),
    notVerifyAuth: true
  },
  {
    path: '/signup/introduction',
    name: '人工智能教育教学能力认证考试',
    exact: true,
    component: lazy(() => import('@/pages/signup/introduction')),
    notVerifyAuth: true
  },
  {
    path: '/signup/usercard',
    name: '人工智能教育教学能力认证考试',
    exact: true,
    component: lazy(() => import('@/pages/signup/userCard')),
    notVerifyAuth: true
  },
  {
    path: '/signup/achievement',
    name: '人工智能教育教学能力认证考试',
    exact: true,
    component: lazy(() => import('@/pages/signup/achievement')),
    notVerifyAuth: true
  },
  {
    path: '/signup/timeout',
    name: '人工智能教育教学能力认证考试',
    exact: true,
    component: lazy(() => import('@/pages/signup/timeOut')),
    notVerifyAuth: true
  },
  {
    path: '/signup/invoice',
    name: '人工智能教育教学能力认证考试',
    exact: true,
    component: lazy(() => import('@/pages/signup/invoice')),
    notVerifyAuth: true
  },
  {
    path: '/404',
    name: '人工智能教育教学能力认证考试',
    exact: true,
    component: lazy(() => import('@/pages/notFound')),
    notVerifyAuth: true
  },
  {
    path: '/exam',
    name: '人工智能教育教学能力认证考试',
    exact: true,
    notVerifyAuth: true,
    component: lazy(() => import('@/pages/exam'))
  },
  {
    path: '/exam/end',
    name: '人工智能教育教学能力认证考试',
    exact: true,
    notVerifyAuth: true,
    component: lazy(() => import('@/pages/exam/end'))
  },
  {
    path: '/exam/welcome',
    name: '人工智能教育教学能力认证考试',
    exact: true,
    notVerifyAuth: true,
    component: lazy(() => import('@/pages/exam/welcome'))
  },
  {
    path: '/exam',
    name: '人工智能教育教学能力认证考试',
    exact: true,
    component: lazy(() => import('@/pages/exam/index'))
  },
  {
    path: '/exam/working',
    name: '人工智能教育教学能力认证考试',
    exact: true,
    component: lazy(() => import('@/pages/exam/working'))
  },
  {
    path: '/exam/workfull',
    name: '人工智能教育教学能力等级认证（中小学阶段）初级考试',
    exact: true,
    component: lazy(() => import('@/pages/exam/workfull'))
  },
  {
    path: '/exam/submitted',
    name: '人工智能教育教学能力认证考试',
    exact: true,
    component: lazy(() => import('@/pages/exam/submitted'))
  },
  {
    path: '/signup/applydetail',
    name: '人工智能教育教学能力认证考试',
    exact: true,
    notVerifyAuth: true,
    // layout: AiGradeApplyLayout,
    component: lazy(() => import('@/pages/signup/aiGradeApply/applyDetail'))
  },
  {
    path: '/signup/applypay',
    name: '人工智能教育教学能力认证考试',
    exact: true,
    notVerifyAuth: true,
    component: lazy(() => import('@/pages/signup/aiGradeApply/applyPay'))
  },
  {
    path: '/signup/paysuccess',
    name: '人工智能教育教学能力认证考试',
    exact: true,
    notVerifyAuth: true,
    component: lazy(() => import('@/pages/signup/aiGradeApply/paySuccess'))
  },

  {
    path: '/evaluation/exam',
    name: '考试-叮当码人工智能教育',
    exact: true,
    notVerifyAuth: true,
    layout: ExamLayout,
    component: lazy(() => import('@/pages/evaluation/exam'))
  },
  {
    path: '/evaluation/examlist',
    name: '考试-叮当码人工智能教育',
    exact: true,
    notVerifyAuth: true,
    layout: EvaluationLayoutNoFooter,
    component: lazy(() => import('@/pages/evaluation/examList'))
  },
  {
    path: '/evaluation/exampublish',
    name: '考试-叮当码人工智能教育',
    exact: true,
    notVerifyAuth: true,
    layout: EvaluationLayoutNoFooter,
    component: lazy(() => import('@/pages/evaluation/examPublish'))
  },
  {
    path: '/evaluation/createExam',
    name: '考试-叮当码人工智能教育',
    exact: true,
    notVerifyAuth: true,
    layout: EvaluationLayout,
    component: lazy(() => import('@/pages/evaluation/createExam'))
  },
  {
    path: '/evaluation/viewResult',
    name: '考试-叮当码人工智能教育',
    exact: true,
    notVerifyAuth: true,
    layout: EvaluationLayout,
    component: lazy(() => import('@/pages/evaluation/viewResult'))
  },
  {
    path: '/qbjapply',
    name: '人工智能趣味编程挑战赛',
    exact: true,
    layout: PCMobileLayout,
    component:
      isDevice === 'pc'
        ? lazy(() => import('@/pages/qbjApply'))
        : lazy(() => import('@/mobile/qbjApply')),
    notVerifyAuth: true
  },
  {
    path: '/evaluation/combination',
    name: '考试-叮当码人工智能教育',
    exact: true,
    notVerifyAuth: true,
    layout: EvaluationLayoutNoFooter,
    component: lazy(() => import('@/pages/evaluation/combination'))
  }
];

export default routes;

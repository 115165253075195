import * as constants from './constants';
import {
  getPaperSubjectList,
  getUserInfo,
  getItemSubject,
  getUserCode,
  getPaperInfo,
  postEval,
  postSave,
  getPreviewPaperSubjectList,
  rateStatisEve,
  getUserPaperDetail,
  ratePageEve,
  // rateRolecheckEve,
  rateDeleteEve,
  getCurDate,
  userMessageEve,
  getItemSubjectForEdu,
  schoolSwitch,
  breakRule
} from '@/api/controller/apiEvaluation';

export const cacheUserCodeAction = (value: any) => ({
  type: constants.CACHE_USER_CODE,
  value
});

export const cacheGameFragmentCodeArrayAction = (value: any) => ({
  type: constants.CACHE_GAME_FRAGMENT_CODE_ARRAY,
  value
});

export const paperSubjectListAction = (value: any) => ({
  type: constants.PAPER_SUBJECT_LIST,
  value
});

export const userInfoAction = (value: any) => ({
  type: constants.EVALUATION_USER_INFO,
  value
});
// 删除用户信息
export const removeUserInfoAction = (value: any) => ({
  type: constants.REMOVE_EVALUATION_USER_INFO,
  value
});

//第一次初始化化当前id
// export const activeSubjectsAction = (value: any) => ({
//   type: constants.PAPER_SUBJECT_LIST_ACTVIE,
//   value
// });
//单道题目的详情
export const itemSubjectAction = (value: any) => ({
  type: constants.PAPER_ITEM_SUBJECT_DETAIL,
  value
});

//用户题目做题详情(纪录)
export const userCodeAction = (value: any) => ({
  type: constants.PAPER_USERCODE_DETAIL,
  value
});

//试卷信息
export const paperInfoAction = (value: any) => ({
  type: constants.PAPER_INFO,
  value
});

//评测
export const evalAction = (value: any) => ({
  type: constants.PAPER_EVAL,
  value
});
//保存
export const saveAction = (value: any) => ({
  type: constants.PAPER_SAVE,
  value
});

//预览试卷题目列表
export const previewPaperAction = (value: any) => ({
  type: constants.PREVIEW_PAPER,
  value
});
export const ratePageAction = (value: any) => ({
  type: constants.PAPER_RATE_PAGE,
  value
});
//查看用户题目列表
export const userPaperAction = (value: any) => ({
  type: constants.USER_PAPER,
  value
});
export const cacheGameAnswerCodeAction = (value: any) => ({
  type: constants.CACHE_GAME_ANSWER_CODE,
  value
});

export const rateStatisAction = (value: any) => ({
  type: constants.RATE_STATIS,
  value
});

export const userMessAction = (value: any) => ({
  type: constants.USER_MESSAGE,
  value
});

//考试管理-B端权限判断
// export const rateRolecheckAction = (value: any) => ({
//   type: constants.RATE_ROLE_CHECK,
//   value
// });
export const errorEvalVisibleAction = (value: any) => ({
  type: constants.CONTROL_ERROR_EVAL_VISIBLE,
  value
});
//考试删除
export const rateDeleteAction = (value: any) => ({
  type: constants.RATE_DELETE,
  value
});

//获取当前时间
export const nowTimeAction = (value: any) => ({
  type: constants.NOW_TIME,
  value
});

//考试违规
export const breakRuleAction = (value: any) => ({
  type: constants.BREAK_RULE,
  value
});

// 数据接口请求request
export const requestPaperSubjectList = (value: any) => {
  return async (dispatch: any) => {
    let res = await getPaperSubjectList(value);
    if (res) {
      if (res.data.code === 0) {
        dispatch(paperSubjectListAction(res.data.data));
      }
    }
  };
};

export const requestUserInfo = () => {
  return async (dispatch: any) => {
    let res = await getUserInfo();
    if (res) {
      if (res.data.code === 0) {
        dispatch(userInfoAction(res.data.data));
      }
    }
  };
};

export const requestItemSubject = (
  value: any,
  payload: any,
  type: any,
  previewSubjectType: any
) => {
  return async (dispatch: any) => {
    let res =
      type !== '4' ? await getItemSubject(value) : await getItemSubjectForEdu({ id: value.id }); //, subjectType: previewSubjectType
    if (res) {
      if (res.data.code === 0) {
        dispatch(itemSubjectAction(res.data.data));
        if (type !== '1' && type !== '4' && payload) {
          if (
            payload.paperId &&
            payload.ratingId &&
            payload.subjectId &&
            payload.userId &&
            payload.role
          ) {
            dispatch(requestUserCode(payload));
          }
        }
      }
    }
  };
};

export const requestUserCode = (value: any) => {
  return async (dispatch: any) => {
    let res = await getUserCode(value);
    if (res) {
      if (res.data.code === 0) {
        dispatch(userCodeAction(res.data.data));
      }
    }
  };
};

export const requestPaperInfo = (value: any) => {
  return async (dispatch: any) => {
    let res = await getPaperInfo(value);
    if (res) {
      if (res.data.code === 0) {
        dispatch(paperInfoAction(res.data.data));
      }
    }
  };
};

export const requestEval = (value: any, save_value: any, type: any, id: any) => {
  return async (dispatch: any) => {
    let res = await postEval(value);
    if (res) {
      if (res.data.code === 0) {
        dispatch(evalAction(res.data.data.resultJson));
        dispatch({ type: 'evaluation/OJ_RESULT', value: res.data.data.result });
        if (type === '3') {
          //type===3不做保存操作
          dispatch({ type: 'evaluation/CLICK_FLAG', value: false });
        }
        console.log(208888, res.data.data);
        if (type === '2') {
          await postSave(
            {
              paperId: save_value.paperId,
              ratingId: save_value.ratingId,
              result: res.data.data.result,
              subjectId: res.data.data.subjectId,
              userAnswer: res.data.data.userAnswer,
              resultJson: res.data.data.resultJson
            },
            id
          ).then(resp => {
            if (resp.data.code === 0) {
              dispatch({ type: 'evaluation/REFRESH' });
              dispatch({ type: 'evaluation/CLICK_FLAG', value: false });
            }
          });
        }
      }
    }
  };
};

// export const requestSave = (value: any) => {
//   return async (dispatch: any) => {
//     let res = await postSave(value, 1);
//     if (res) {
//       if (res.data.code === 0) {
//         dispatch(saveAction(res.data.data));
//       }
//     }
//   };
// };

export const requestpPreviewPaper = (value: any) => {
  return async (dispatch: any) => {
    let res = await getPreviewPaperSubjectList(value);
    if (res) {
      if (res.data.code === 0) {
        dispatch(previewPaperAction(res.data.data));
      }
    }
  };
};

export const requestpUserPaper = (value: any) => {
  return async (dispatch: any) => {
    let res = await getUserPaperDetail(value);
    if (res) {
      if (res.data.code === 0) {
        dispatch(userPaperAction(res.data.data));
      }
    }
  };
};

export const requestRatePage = (value: any) => {
  return async (dispatch: any) => {
    let res = await ratePageEve(value);
    if (res) {
      if (res.data.code === 0) {
        dispatch(ratePageAction({ listType: value.listType, value: res.data.data }));
      }
    }
  };
};

export const requestRateStatis = (value: any) => {
  return async (dispatch: any) => {
    let res = await rateStatisEve(value);
    if (res) {
      if (res.data.code === 0) {
        dispatch(rateStatisAction(res.data.data));
      }
    }
  };
};

export const requestUserMessage = () => {
  return async (dispatch: any) => {
    let res = await userMessageEve();
    if (res) {
      if (res.data.code === 0) {
        dispatch(userMessAction(res.data.data));
      }
    }
  };
};

export const requestRateDelete = (value: any) => {
  return async (dispatch: any) => {
    let res = await rateDeleteEve(value);
    if (res) {
      if (res.data.code === 0) {
        dispatch(rateDeleteAction(res.data.data));
      }
    }
  };
};

export const requestNowTime = () => {
  return async (dispatch: any) => {
    let res = await getCurDate();
    if (res) {
      if (res.data.code === 0) {
        dispatch(nowTimeAction(res.data.data));
      }
    }
  };
};

export const fetchShoolSwitch = (schoolId: any) => {
  return async (dispatch: any) => {
    let res = await schoolSwitch(schoolId);
    if (res) {
      if (res.data.code === 0) {
        window.location.replace('/');
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
};

export const requestbreakRule = (ratingId: any, userId: any) => {
  return async (dispatch: any) => {
    let res = await breakRule(ratingId, userId);
    if (res) {
      if (res.data.code === 0) {
        dispatch(breakRuleAction(res.data.data));
      }
    }
  };
};

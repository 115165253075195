import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { MenuBase } from './config';

// import queryString from 'query-string';
import cookies from 'js-cookie';
import Login from '@/pages/exam/index';
import NotFound from '@/pages/notFound';

// 如果登陆之后可以利用redux修改该值
const token = cookies.get('token'); //cookies.get('TOKEN');
const isLogin = token && token !== 'null' && token !== 'undefined';
// 默认未登录的时候返回的页面，可以自行设置
// const authPath = '/login';
/**
 *
 * 解析路由参数
 * @param props
 * @memberof CRouter
 * @returns {*}
 */
const SuspenseComponent = (props: any, extraProps: any, route: MenuBase) => {
  return (
    <Suspense fallback={<div></div>}>
      <route.component {...props} {...extraProps} route={route} />
    </Suspense>
  );
};

function renderRoutes(routes: MenuBase[], extraProps = {}, switchProps = {}) {
  return routes ? (
    <Switch {...switchProps}>
      {routes.map((route: MenuBase, i: number) => {
        return (
          <Route
            key={route.path || i}
            path={route.path}
            exact={route.exact}
            render={props => {
              return (
                <>
                  {!!(route.notVerifyAuth || isLogin) ? (
                    <DocumentTitle title={route.name}>
                      {route.layout ? (
                        <route.layout>{SuspenseComponent(props, extraProps, route)}</route.layout>
                      ) : (
                        SuspenseComponent(props, extraProps, route)
                      )}
                    </DocumentTitle>
                  ) : (
                    <DocumentTitle title={'登录'}>
                      <Login></Login>
                    </DocumentTitle>
                  )}
                </>
              );
            }}
          />
        );
      })}
      <Route key="/404" component={NotFound} />
    </Switch>
  ) : null;
}

export default renderRoutes;

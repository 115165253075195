import React from 'react';
import './index.scss';
import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Message from 'antd/lib/message';
import { postLogin } from '@/api/controller/apiExam';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import { hex_md5 } from '@/utils/encryption';
// import { requestExamInfoData } from '@/store/exam/action';
// import { useDispatch } from 'react-redux';

const Login = (props: any) => {
  // const dispatch = useDispatch();

  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    postLogin({
      idCardLast: hex_md5(values.password),
      ticketNo: values.ticketNo
    })
      .then(resp => {
        console.log(resp.data.code);
        if (resp.data.code === 0) {
          const data = resp.data.data;
          Cookies.set('examId', data.examId);
          // Cookies.set('EXAM_TOKEN', data.token);
          Cookies.set('userId', data.userId);
          Cookies.set('token', data.token); //TOKEN
          window.location.href = '/exam/welcome';
        } else if (resp.data.code === 354) {
          props.history.push('/exam/end');
        } else {
          Message.warning(resp.data.msg);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div className="login-wrap">
      <div className="cover flex flex-v flex-align-center">
        <img className="img" src={require('../../assets/images/exam_logo.png')} alt="" />
        <div className="title">人工智能教育教学能力认证（中小学阶段）考试</div>
        <div className="form-box aiGradeApply-form-box">
          <Form form={form} initialValues={{ remember: true }} size={'large'} onFinish={onFinish}>
            <Form.Item
              name="ticketNo"
              validateTrigger="onBlur"
              rules={[{ required: true, message: '请输入您的准考证！' }]}
            >
              <Input type="text" placeholder="准考证" autoComplete="off" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: '请输入您的身份证后六位！' }]}
            >
              <Input.Password maxLength={6} placeholder="身份证后六位" autoComplete="off" />
            </Form.Item>
            <Form.Item>
              <Button
                style={{ width: '100%' }}
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="surport">
        <p className="p1">如有疑问，请致电：028-61318891</p>
        <p className="p2">由叮当码提供技术支持</p>
      </div>
    </div>
  );
};

export default withRouter(Login);
